// src/components/ourservice/OurService.jsx
import React, { useEffect,useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styles from './OurService.module.css'; // Assuming you're using CSS modules
import video from '../../assets/videos/1.mp4'

const OurService = () => {
  const [activeSection, setActiveSection] = useState(null); // Track which section is open

  const toggleSection = (section) => {
    setActiveSection((prevSection) => (prevSection === section ? null : section));
  };
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Background animation for service items
    gsap.fromTo(
      `.${styles.itemservice1}, .${styles.itemservice2}, .${styles.itemservice3}, .${styles.itemservice4}, .${styles.itemservice5}, .${styles.websites}, .${styles.apps}`,
      { backgroundPosition: 'center center' },
      {
        backgroundPosition: '100% 100%',
        duration: 10,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
      }
    );

    // Right section animation
    gsap.fromTo(
      `.${styles.rightSection}`,
      { y: window.innerHeight },
      {
        y: 0,
        scrollTrigger: {
          trigger: `.${styles.ourservice}`,
          start: 'top 80%',
          end: 'top top',
          once: true,
          markers: false, // Enable markers if you need debugging
          scroller: document.body, // Use Lenis for smooth scrolling if implemented
        },
      }
    );
//video 
    gsap.fromTo(
        `.${styles.videoContainer}`,
        {   clipPath: 'circle(0% at 0% 100%)', },
        {
            clipPath: 'circle(150% at 50% 50%)',
          scrollTrigger: {
            trigger: `.${styles.ourservice}`,
            start: 'top center',
            end: 'top top',
            scrub: true,
            markers: false, // Enable markers if you need debugging
            scroller: document.body, // Use Lenis for smooth scrolling if implemented
          },
        }
      );
    // Left section animation
    gsap.fromTo(
      `.${styles.leftSection}`,
      { opacity: 0, y: window.innerHeight },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: `.${styles.ourservice}`,
          start: 'top 70%',
          end: 'top top',
          once: true,
          markers: false,
          scroller: document.body,
        },
        onComplete: () => {
          gsap.fromTo(
            `.${styles.gridItemService}`,
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.0002,
              stagger: 1,
              scrollTrigger: {
                trigger: `.${styles.leftSection}`,
                start: 'top center',
                once: true,
              },
            }
          );
        },
      }
    );
  }, []);

  return (
    <div className={styles.ourservice}>
<div className={styles.videoContainer}>
  <div className={styles.video1}>
    <video autoPlay loop muted playsInline >
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
 
 
</div>
      <div className={styles.leftSection}>
        <div className={`${styles.gridItemService} ${styles.itemservice1}`}></div>
        <div className={`${styles.gridItemService} ${styles.itemservice2}`}></div>
        <div className={`${styles.gridItemService} ${styles.itemservice3}`}></div>
        <div className={`${styles.gridItemService} ${styles.itemservice4}`}></div>
        <div className={`${styles.gridItemService} ${styles.itemservice5}`}></div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.serviceTitle}>
          <h1>خدماتنا</h1>
        </div>
       <div className={styles.serviceContainer}>

       <div className={`${styles.serviceBox} ${styles.websites} `}>
         <div > <h3>المواقع</h3></div>
          <div className={styles.info}>
          <p>
          نقوم ببناء مواقع إلكترونية من الصفر تلبي احتياجاتك الخاصة . 
        </p>
          </div>
        </div>
        <div className={`${styles.serviceBox} ${styles.apps}`}>
         
          <h3>التطبيقات</h3>
          <div className={styles.info}>
          <p>
          نقدّم تطبيقات ذات جودة عالية وسهلة الاستخدام . 
        </p>
          </div>
        </div>
       </div>
      </div>
    </div>
  );
};

export default OurService;

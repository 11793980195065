// src/components/home/HomePage.jsx
import React,{useEffect} from 'react';
import ParallaxSection from '../components/ParallaxSection/ParallaxSection';
import AboutSection from '../components/AboutSection/AboutSection';
import OurWorkSection from '../components/OurWorkSection/OurWorkSection';
import OurService from '../components/OurService/OurService';
import styles from './HomePage.module.css'; // Optional: Create a CSS module for homepage styles
import ContactUs from '../components/ContactUs/ContactUs';
import { logPageView } from '../services/firebase/analytics';
const HomePage = () => {
  useEffect(() => {
    // Log a page view when the component mounts
    logPageView('Home Page');
  }, []);
  return (
    <div className={styles.homePage}>
      {/* Parallax Section */}
      <ParallaxSection />

      {/* About Section */}
      <AboutSection />
      <OurWorkSection />
      <OurService/>
      <ContactUs/>
    </div>
  );
}; 

export default HomePage;

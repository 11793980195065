import React, { useState } from 'react';
import { FaLinkedin, FaInstagram, FaTiktok, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { AiFillTwitterCircle } from 'react-icons/ai';
import styles from './ContactUs.module.css'; // CSS Module
import { logSocialMediaClick } from '../../services/firebase/analytics';
const ContactUs = () => {
  const [hovered, setHovered] = useState(false);

  return (
   <div className={styles.container}>
   <div 
      className={styles.ContactUs} 
      onMouseEnter={() => setHovered(true)} 
      onMouseLeave={() => setHovered(false)}
    >
      <div className={styles.contactTitle}><p>تواصل معنا  </p></div>

      <div className={`${styles.socialIcons} ${hovered ? styles.hovered : ''}`}>
      <a 
        href="https://www.linkedin.com" 
        target="_blank" 
        rel="noopener noreferrer" 
        className={styles.socialIcon}
        onClick={() => logSocialMediaClick('LinkedIn')}  // Log LinkedIn click
      >
        <FaLinkedin />
      </a>

      <a 
        href="https://x.com/jibalapps" 
        target="_blank" 
        rel="noopener noreferrer" 
        className={styles.socialIcon}
        onClick={() => logSocialMediaClick('Twitter')}  // Log Twitter (X) click
      >
        <AiFillTwitterCircle />
      </a>

      <a 
        href="https://www.instagram.com/jibalapps?igsh=MXVrczNqbGFhbXNiMg==" 
        target="_blank" 
        rel="noopener noreferrer" 
        className={styles.socialIcon}
        onClick={() => logSocialMediaClick('Instagram')}  // Log Instagram click
      >
        <FaInstagram />
      </a>

      <a 
        href="https://www.tiktok.com/@jibalapps?_t=8qA1shyJZ2t&_r=1" 
        target="_blank" 
        rel="noopener noreferrer" 
        className={styles.socialIcon}
        onClick={() => logSocialMediaClick('TikTok')}  // Log TikTok click
      >
        <FaTiktok />
      </a>

      <a 
        href="mailto:jibalapps@gmail.com" 
        className={styles.socialIcon}
        onClick={() => logSocialMediaClick('Email')}  // Log Email click
      >
        <FaEnvelope />
      </a>

      <a 
        href="https://wa.me/+966554711494" 
        target="_blank" 
        rel="noopener noreferrer" 
        className={styles.socialIcon}
        onClick={() => logSocialMediaClick('WhatsApp')}  // Log WhatsApp click
      >
        <FaWhatsapp />
      </a>
    </div>
    </div>
   </div>  
  );
};

export default ContactUs;

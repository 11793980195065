// src/components/ourwork/OurWorkSection.jsx
import React, { useEffect } from 'react';
import styles from './OurWorkSection.module.css'; // Assuming you're using CSS modules
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import googlePlay from '../../assets/icons/google-play.png';
import w from '../../assets/icons/w.webp';
import q from '../../assets/icons/q.webp';
import f from '../../assets/icons/f.png';

const OurWorkSection = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // ClipPath animation for photos
    gsap.set(`.${styles.photo}:not(:first-child)`, { clipPath: 'inset(100% 0% 0% 0%)' });

    const animation = gsap.to(`.${styles.photo}:not(:first-child)`, {
      clipPath: 'inset(0% 0% 0% 0%)',
      duration: 1,
      stagger: 1,
    });

    ScrollTrigger.create({
      trigger: `.${styles.ourWorkSection}`,
      start: 'top top',
      end: 'bottom bottom',
      pin: `.${styles.left}`,
      animation: animation,
      scrub: true,
       markers: false, // Set to true if you need debugging
    });
  // Set initial clip-path for non-first child elements
  gsap.set(`.${styles.color}:not(:first-child)`, { clipPath: 'circle(0% at 0% 100%)' });

  // Create the animation
  const animationBg = gsap.to(`.${styles.color}:not(:first-child)`, {
    clipPath: 'circle(150% at 50% 50%)',
    duration: 1,
    stagger: 1,
    onUpdate: function() {
      // Get the computed background color of the first non-child element
      const firstNonChild = document.querySelector(`.${styles.color}:not(:first-child)`);
      const bgColor = getComputedStyle(firstNonChild).backgroundColor;
      
      // Set the background color of the target element
      document.querySelector(`.${styles.bg}`).style.backgroundColor = bgColor;
    }
  });

  // Create ScrollTrigger
  ScrollTrigger.create({
    trigger: `.${styles.ourWorkSection}`,
    start: 'top top',
    end: 'bottom bottom',
    pin: `.${styles.bg}`,
    animation: animationBg,
    scrub: true,
    markers: false, // Set to true if you need debugging
  });

    
    // Right section animation
    gsap.fromTo(
      `.${styles.right}`,
      { y: window.innerHeight },
      {
        y: 0,
        scrollTrigger: {
          trigger: `.${styles.ourWorkSection}`,
          start: 'top 40%',
          end: 'top top',
          once: true,
          markers: false,
          scroller: document.body, // Use Lenis for smooth scrolling if implemented
        },
      }
    );

     // left section animation
    
    // Title animation
 // Right section animation
 gsap.fromTo(
  `.${styles.title}`,
  { y: window.innerHeight },
  {
    y: 0,
    scrollTrigger: {
      trigger: `.${styles.ourWorkSection}`,
      start: 'top 70%',
      end: 'top top',
      once: true,
      markers: false,
      scroller: document.body, // Use Lenis for smooth scrolling if implemented
    },
  }
);

    // Left section animation
    // gsap.fromTo(
    //   `.${styles.left}`,
    //   { opacity: 1, y: window.innerHeight },
    //   {
    //     opacity: 1,
    //     y: 0,
    //     scrollTrigger: {
    //       trigger: `.${styles.ourWorkSection}`,
    //       start: 'top 70%',
    //       end: 'top top',
    //       once: true,
    //       markers: false,
    //       scroller: document.body,
    //     },
    //   }
    // );
  }, []);

  return (
    <div className={styles.main}>
        <div className={styles.bg}>
          <div className={`${styles.color} ${styles.one}`}></div>
          <div className={`${styles.color} ${styles.two}`}></div>
          <div className={`${styles.color} ${styles.three}`}></div>
        </div>
 
    <div className={styles.ourWorkSection}>
   
      {/* Title */}
      <div className={styles.title}>
        <h1>أعمالنا</h1>
      </div>

      {/* Left Section */}
      <div className={styles.left}>
        <div className={styles.photos}>
          <div className={`${styles.photo} ${styles.fusulApp}`}></div>
          <div className={`${styles.photo} ${styles.qusasat}`}></div>
          <div className={`${styles.photo} ${styles.watheeq}`}></div>
        </div>
      </div>

      {/* Right Section */}
      <div className={styles.right}>
        <div className={styles.detailsWrapper}>
          <div className={styles.details}>
          <img  className={styles.logo}
           alt='' src={f} 
   
        style={{ cursor: 'pointer' }} 
        />
            <div className={styles.headline}>فصول</div>
            <p>
              تطبيق يجتوي على عديد من المميزات التي يحتاجها طلاب الجامعة .
              اضافة فصول ومواد كل فصل وجدول وحساب المعدل وتنظيم وقت الدراسة 
            </p>
            <img  className={styles.image}
           alt='' src={googlePlay} 
      
        style={{ cursor: 'pointer' }} 
        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.jibalapps.fusulapp&pcampaignid=web_share', '_blank')}
      />
          </div>

          <div className={styles.details}>
          <img  className={styles.logo}
           alt='' src={q} 
   
        style={{ cursor: 'pointer' }} 
        />
            <div className={styles.headline}>قصاصات</div>
            <p>
            قصاصات - رفيقك في القراءة 
             لقراء الكتب  تطبيق  ينظيم إقتباساتك وملاحظاتك حسب الكتاب أو الكاتب والقسم يسهل الوصول لها ومشاركتها.
            </p>
            <img  className={styles.image}
           alt='' src={googlePlay} 
      
        style={{ cursor: 'pointer' }} 
        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.jibalapp.qusasat_app&pcampaignid=web_share', '_blank')}
      />
          </div>

          <div className={styles.details}>
          <img  className={styles.logo}
           alt='' src={w} 
   
        style={{ cursor: 'pointer' }} 
        />
            <div className={styles.headline}>وثيق</div>
            <p>
              تطبيق للأذكار اليومية  ومواقيت بتصميم حديث ومختصر
            </p>
            <img  className={styles.image}
           alt='' src={googlePlay} 
      
        style={{ cursor: 'pointer' }} 
        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.jibalapps.watheeq&pcampaignid=web_share', '_blank')}
      />
          </div>
        </div>
      </div>
    </div>   </div>
  );
};

export default OurWorkSection;

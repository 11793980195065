// src/components/home/ParallaxSection.jsx
import React, { useEffect } from 'react';
import styles from './ParallaxSection.module.css';
import Lenis from '@studio-freight/lenis';
import moon from '../../assets/images/moon.png'
import sky from '../../assets/images/skye.png'
import videoClouds from '../../assets/videos/clouds.mp4'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../../styles/common.css'
const ParallaxSection = () => {
  useEffect(() => {
    // Initialize Lenis for smooth scrolling
    const lenis = new Lenis({
      lerp: 0.1,
      smooth: true,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    // Update ScrollTrigger on scroll
    lenis.on('scroll', ScrollTrigger.update);

    // Register ScrollTrigger with GSAP
    gsap.registerPlugin(ScrollTrigger);

    // Parallax Animations
    gsap.to(`.${styles.moon}`, {
      y: '100vh',
      ease: 'none',
      scrollTrigger: {
        trigger: `.${styles.parallaxContainer}`,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        scroller: document.body, // Use Lenis to control body scroll
      },
    });

    gsap.to(`.${styles.sky}`, {
      x: '-100%',
      ease: 'none',
      scrollTrigger: {
        trigger: `.${styles.parallaxContainer}`,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        scroller: document.body,
      },
    });

    gsap.to(`.${styles.text}`, {
      y: '100vh',
      ease: 'none',
      scrollTrigger: {
        trigger: `.${styles.parallaxContainer}`,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        scroller: document.body,
      },
    });

    // Clean up on unmount
    return () => {
      lenis.destroy();
      ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // Kills all active ScrollTriggers
    };
  }, []);

  return (
    <div className={'section '+ styles.firstSection}>
      <div className={styles.parallaxContainer}>
        <div  alt="Mountain" className={`${styles.parallax} ${styles.mountain}`} ></div>
        <div className={`${styles.parallax} ${styles.trees}`}></div>
        <img src={moon} alt="Moon" className={`${styles.parallax} ${styles.moon}`} />
        <img src={sky} alt="Sky" className={`${styles.parallax} ${styles.sky}`} />
        <video className={styles.videoBackground} autoPlay loop muted playsInline>
          <source src={videoClouds} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={`${styles.parallax} ${styles.text}`}>جــبـال</div>
      </div>
    </div>
  );
};

export default ParallaxSection;

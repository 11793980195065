import { analytics } from "./firebaseConfig";
import { logEvent } from "firebase/analytics";

// Function to log button clicks
export const logSocialMediaClick = (platform) => {
  logEvent(analytics, 'social_media_click', {
    platform: platform,
  });
};

// Function to log a page view
export const logPageView = (pageTitle) => {
    logEvent(analytics, 'page_view', { page_title: pageTitle });
  };
import './App.css';
import Navbar from './components/Navbar/Navbar';
import ParallaxSection from './components/ParallaxSection/ParallaxSection';
import HomePage from './pages/HomePage';

function App() {
  return (
    <div className="App">
   {/* <Navbar/> */}
   <HomePage/>
    </div>
  );
}

export default App;

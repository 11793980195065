// src/components/layout/Navbar.jsx
import React from 'react';
import styles from './Navbar.module.css';

const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.navLogo}></div>
      <ul className={styles.navMenu}>
        <li className={styles.active}><a href='/#'>الرئيسية</a></li>
        <li><a href='/#'>من نحن</a></li>
        <li><a href='/#'>أعمالنا</a></li>
        <li><a href='/#'>خدماتنا</a></li>
        <li><a href='/#'>تواصل معنا</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
